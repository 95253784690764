export const appData = {
    name: 'call_card',
    version: '1.2.0'
}

export const fields = {
    lead: [
        { field: "TITLE", label: "Название лида" },
        { field: "NAME", label: "Имя" },
        { field: "LAST_NAME", label: "Фамилия" },
        { field: "SECOND_NAME", label: "Отчество" },
        { field: "POST", label: "Должность" },
        { field: "COMPANY_TITLE", label: "Компания" },
        { field: "PHONE", label: "Телефон", type: "array" },
        { field: "EMAIL", label: "E-mail", type: "array" },
        { field: "COMMENTS", label: "Комментарий", type: "textarea" },
    ],
    contact: [
        { field: "NAME", label: "Имя" },
        { field: "LAST_NAME", label: "Фамилия" },
        { field: "SECOND_NAME", label: "Отчество" },
        { field: "POST", label: "Должность" },
        { field: "COMPANY_ID", label: "Компания", type: "CompaniesOfContact", disabled: true },
        { field: "PHONE", label: "Телефон", type: "array" },
        { field: "EMAIL", label: "E-mail", type: "array" },
        { field: "COMMENTS", label: "Комментарий", type: "textarea" },
    ],
    company: [
        { field: "TITLE", label: "Название" },
        {
            field: "COMPANY_TYPE",
            label: "Тип компании",
            type: "select",
            options: [
                { value: "CUSTOMER", label: "Клиент" },
                { value: "Поставщик", label: "Поставщик" },
                { value: "Конкурент", label: "Конкурент" },
                { value: "Партнер", label: "Партнер" },
                { value: "Другое", label: "Другое" },
            ],
        },
        {
            field: "INDUSTRY",
            label: "Сфера деятельности",
            type: "select",
            options: [
                { value: "Клиент", label: "Клиент" },
                { value: "Поставщик", label: "Поставщик" },
                { value: "Конкурент", label: "Конкурент" },
                { value: "Партнер", label: "Партнер" },
                { value: "Другое", label: "Другое" },
            ],
        },
        { field: "PHONE", label: "Телефон", type: "array" },
        { field: "EMAIL", label: "E-mail", type: "array" },
        { field: "COMMENTS", label: "Комментарий", type: "textarea" },
    ],
};

export const matchFieldTypes = {
    'REVENUE': 'price',
    'OPPORTUNITY': 'price'
}
