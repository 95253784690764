import React, { useState, useEffect } from "react";

import "../../static/styles/app.scss";
import EntityEdit from "../EntityEdit";
import BxApi from "../../services/bx-api";

import './i18n';
import { withTranslation } from 'react-i18next';
import _ from "lodash";

let timer;

const EntityData = ({ entity: entityFromProps, type, onBindDealChange, deals }) => {
    const [ entity, setEntity ] = useState(entityFromProps);
    const [ saved, setSaved ] = useState(entityFromProps);
    const [ saveStatus, setSaveStatus ] = useState(null);

    const clearSaveStatus = () => {
        setTimeout(() => {
            setSaveStatus(null);
        }, 3600);
    };

    const changeEntity = (value, field) => {
        setEntity({ ...(entity || {}), [field]: value });
    };

    const saveEntity = () => {
        const bxApi = new BxApi();
        bxApi
            .crmEntityUpdate(type, entity.ID, entity)
            .then(async (result) => {
                if (!result) {
                    setSaveStatus(false);
                    return;
                }
                setSaveStatus(true);
                clearSaveStatus();
                const newEntity = await getEntity();
                if (!newEntity) {
                    return;
                }
                setEntity(newEntity);
                setSaved(newEntity);
            })
            .catch((err) => {
                setSaveStatus(false);
            });
    };

    const getEntity = async () => {
        const bxApi = new BxApi();
        return await bxApi.rest(`crm.${type}.get`, { id: entity.ID })
            .then(entity => {
                return entity;
            })
            .catch((err) => {
                return null;
            });
    }

    useEffect(() => {
        const { DATE_MODIFY: _1, ...oldEntity } = saved;
        const { DATE_MODIFY: _2, ...newEntity } = entity;
        const entityDidntChange = _.isEqual(oldEntity, newEntity);
        if (!entityDidntChange) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                saveEntity();
            }, 3000);
        }
    }, [entity, saved]);

    return (
        <EntityEdit
            entity={entity}
            type={type}
            changeEntity={changeEntity}
            saveEntity={saveEntity}
            saveStatus={saveStatus}
            onBindDealChange={onBindDealChange}
            deals={deals}
        />
    );
}

export default withTranslation()(EntityData);